<template>
  <div>
    <modal-edit-taxes
      v-if="showEditTaxPercentages"
      :showModal="showEditTaxPercentages"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditTaxPercentages"
    >
      <template v-slot:title>VAT rates</template>
    </ui-section-header>

    <message
      :message="'Specify which VAT rates can be used in the application'"
    />

    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>Rate</th>
          <!-- <th>Is active</th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(percentage, index) in locationState.TaxPercentages"
          :key="index"
        >
          <td>{{ percentage.TaxPercentage }}%</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const Message = () => import('@/components/UI/Message')

const ModalEditLocationTaxPercentages = () =>
  import('@/components/Locations/Settings/ModalEditLocationTaxPercentages')

export default {
  components: {
    message: Message,
    'modal-edit-taxes': ModalEditLocationTaxPercentages,
  },

  props: {},

  data() {
    return {
      showEditTaxPercentages: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
  },

  created() {},

  methods: {
    setShowEditTaxPercentages() {
      this.showEditTaxPercentages = true
    },

    hideModal() {
      this.showEditTaxPercentages = false
    },
  },
}
</script>

<style></style>
